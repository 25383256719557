@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300&family=Roboto:wght@500&display=swap");
@import "./data/styles.css";

body {
	margin: 0;
	min-height: 100vh;
	font-family: var(--primary-font);
	background-color: var(--background-color);
	overflow-y: scroll;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
