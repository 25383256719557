.dark-mode-switch {
	position: relative;
}

/* .light-mode {
  background-color: #e2e2e2;
} */

.switch {
	position: absolute;
	top: 50%;
	left: 4px;
	width: 30px;
	height: 14px;
	background-color: #888;
	border-radius: 7px;
	transition: background-color 0.3s ease;
	transform: translateY(-50%);
}

.switch::before {
	content: "";
	position: absolute;
	top: 2px;
	left: 2px;
	width: 10px;
	height: 10px;
	background-color: #fff;
	border-radius: 50%;
	transition: transform 0.3s ease;
}

.dark-mode .switch {
	background-color: #171717;
}

.dark-mode .switch::before {
	transform: translateX(16px);
}

input[type="checkbox"] {
	display: none;
}

label {
	display: block;
	width: 36px;
	height: 18px;
	cursor: pointer;
}

label:hover .switch {
	background-color: #666;
}

label:hover .switch::before {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
	.switch {
		width: 24px;
		height: 12px;
		left: 3px;
	}

	.switch::before {
		width: 8px;
		height: 8px;
		top: 2px;
		left: 2px;
	}

	label {
		width: 30px;
		height: 15px;
	}

	.dark-mode .switch::before {
		transform: translateX(11px);
	}
}
