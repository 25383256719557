@import "../../data/styles.css";

.dark-mode .subtitle a {
	color: var(--link-color);
}

.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	display: flex;
	position: fixed;
	border-radius: 50%;
	box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
	top: 4vh;
}

.contact-subtitle {
	width: 100% !important;
	font-size: 18px !important;
	font-family: var(--primary-font);
}

.contact-subtitle a {
	text-decoration: none;
}

.contact-subtitle a:hover {
	color: var(--link-color);
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
	padding-bottom: 2%;
}

.contact-main {
	min-height: calc(100vh - 540px);
	display: flex;
	padding-top: 40px;
}

.socials-container {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.contact-form {
	flex-basis: 900px;
}

@media (max-width: 1024px) {
	.contact-container {
		margin-top: 90px;
	}

	.contact-main {
		flex-direction: column;
	}

	.contact-form {
		flex-basis: 100%;
		order: 2;
	}
}
