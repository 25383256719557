.research-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
	margin-top: 100px;
}

.research-logo {
	display: flex;
	position: fixed;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.research-page-content {
    margin-top: 100px; /* Adjust the value as needed */
	/* padding-top: 50px;  */
}

.research-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-top: 120px;
}

.research-buttons {
	display: flex;
	justify-content: left;
	padding-top: 30px;
	padding-bottom: 30px;
}

.research-button {
	margin-right: 20px;
	outline: 2px solid var(--link-color);
	background-color: var(--context-background-color);
	color: var(--link-color);
	border: none;
	padding: 10px 15px;
	border-radius: 30px;
	font-size: 14px;
	cursor: pointer;
	max-height: 35px;
	overflow: hidden;
}

@media (max-width: 1024px) {
	.research-container {
		margin-top: 90px;
	}
}

@media (max-width: 600px) {
	.research-project {
		width: calc(100% / 2);
	}

	.research-button {
		font-size: 12px;
	}
}
