.all-projects-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-left: -35px;
	margin-right: -35px;
}

.all-projects-project {
	width: calc(100% / 3);
	box-sizing: border-box;
	padding: 20px;
	overflow: hidden;
}

.all-projects-carousel {
	display: none;
}

@media (max-width: 1024px) {
	.all-projects-project {
		flex-basis: 43.5%;
		/* width: calc(100% / 2); */
	}
	.all-projects-container {
		justify-content: center;
	}
}

@media (max-width: 720px) {
	.all-projects-carousel {
		display: block;
		padding-bottom: 5%;
	}

	.all-projects-project {
		width: 100%;
		padding: 5px;
	}

	.all-projects-container {
		display: none;
	}

	.carousel-slider > .control-dots {
		margin-bottom: 25px;
	}
}
